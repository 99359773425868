require('./bootstrap');
require('./sb-admin-2');

require('./jquery.mask');
require('./jquery.maskMoney.min');
require('./jquery.printPage');
require('./select2');
require("flatpickr");
require('flatpickr/dist/l10n/pt');

var token = $('meta[name="api"]').attr('content');
var csrf = $('meta[name="csrf-token"]').attr('content');

readURL = function(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            $('#thumb').attr('src', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
    }
};

// Deletar Registros
$('#ConfirmDelete').on('show.bs.modal', function(e) {
    $nome = $(e.relatedTarget).parent().parent().find('td').eq(1).text();
    var id = $(e.relatedTarget).attr('data-id');
    var message = "Realmente deseja Apagar o Registro de <b>"+ $nome+"</b> cod# "+ id +" ?";
    var act = $(e.relatedTarget).attr('href');
    var form = '<form name="form" method="post" action="'+act+'"> ' +
        '<input type="hidden" name="_method" value="DELETE">'
        + '<input name="_token" type="hidden" value="'+csrf+'">'
        + '<input type="submit" value="Confirma" class="btn btn-danger btn-sm active">'
        + '</form>';
    $(this).find('.modal-body p').html(message);
    $(this).find('.modal-footer').html(form);
});

// Disable button on submit
$(document).ready(function () {
    $('form').submit(function() {
        var el = $(this);
        el.find("button[type='submit'],input[type='submit']").prop('disabled',true);
        $('select').removeAttr('disabled');
        setTimeout(function(){el.prop('disabled', false); }, 7000);
    });
});

// modal
$("body").on('click','.loadModal,.bigModal',function(ev) {
    ev.preventDefault();
    if($(this).hasClass("bigModal")){
        $("#myModal .modal-dialog").addClass('modal-lg');
    }
    var target = $(this).attr("href");
    $("#myModal .modal-content").load(target, function() {

        // $('.tel').mask(maskBehavior, options);
        $('#myModal').modal({
            backdrop: 'static',
            keyboard: false
        });
        $('.selectizeModal').select2({
            placeholder: "Selecione uma opção",
            width : '100%',
            selectOnClose: true
        });

        $(".dataModal").flatpickr({
            "locale": "pt",
            'dateFormat': "d/m/Y",
            'allowInput' : true,
        });
        $('.dataHora').mask('99/99/9999 99:99');
        $('.hora').mask('99:99');
        $(".money").maskMoney({prefix:'R$ ', allowNegative: true, thousands:'.', decimal:',', affixesStay: false});
    });
});

$('body').on('hidden.bs.modal', '.modal', function () {
    $(this).removeData('bs.modal');
});

$('body').on('shown.bs.modal', '.modal', function () {
    $('#focusModal').focus();
});

$('body').on('click','.printAcerto,.printPage',function (e) {
    e.preventDefault();
    $(this).printPage({
        message:"Seu documento está sendo impresso",
    });
    $(this).click();
});

var maskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    options = {onKeyPress: function(val, e, field, options) {
            field.mask(maskBehavior.apply({}, arguments), options);
        }
    };

$('.tel').mask(maskBehavior, options);
$(".cep").mask("99.999-999");
$(".cnpj").mask("99.999.999/9999-99");
$(".cpf").mask("999.999.999-99");
$('.hora').mask('99:99');

$('.dataModal').mask('99/99/9999');
$('.anoModelo').mask('9999/9999');
$('.dataHora').mask('99/99/9999 99:99');
$('.monetary').maskMoney();
$(".money").maskMoney({prefix:'R$ ', allowNegative: true, thousands:'.', decimal:',', affixesStay: false});

$('body').on('focus', '.data', function(){
    $(this).mask("99/99/9999");
});

$('body').on('focus', '.money', function(){
    $(this).maskMoney({prefix:'R$ ', allowNegative: true, thousands:'.', decimal:',', affixesStay: false});
});

var CpfCnpjMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length <= 11 ? '000.000.000-009' : '00.000.000/0000-00';
    },
    cpfCnpjpOptions = {
        onKeyPress: function(val, e, field, options) {
            field.mask(CpfCnpjMaskBehavior.apply({}, arguments), options);
        }
    };

$('.cpfcnpj').mask(CpfCnpjMaskBehavior, cpfCnpjpOptions);

//Busca cep
$(document).on('blur','.cep',function() {
    var cep = $(this).val().replace(/\D/g, '');
    if (cep != "") {
        var validacep = /^[0-9]{8}$/;
        if(validacep.test(cep)) {
            //Preenche os campos com "..." enquanto consulta webservice.
            $(".rua").val("...");
            $(".bairro").val("...");
            $(".cidade").val("...");
            $(".uf").val("...");

            $.getJSON("//viacep.com.br/ws/"+ cep +"/json/?callback=?", function(dados) {
                if (!("erro" in dados)) {
                    console.log(dados);
                    //Atualiza os campos com os valores da consulta.
                    $(".rua").val(dados.logradouro);
                    $(".bairro").val(dados.bairro);
                    $(".cidade").val(dados.localidade);
                    //$('#city').select2('data', {id: dados.ibge, text: dados.localidade+' - '+dados.uf});
                    var newOption = new Option(dados.localidade+' - '+dados.uf, dados.ibge, true, true);
                    $('#city').append(newOption).trigger('change');
                } //end if.
                else {
                    $('.rua').focus();
                    alert("CEP não encontrado.");
                }
            });
        } //end if.
        else {
            $('.rua').focus();
            alert("Formato de CEP inválido.");
        }
    }
});

$("#checkAll").click(function(){
    $('input:checkbox').not(this).prop('checked', this.checked);
});

$(document).ready(function () {
    function matchCustom(params, data) {

        // If there are no search terms, return all of the data
        if ($.trim(params.term) === '') {
            return data;
        }
        // Do not display the item if there is no 'text' property
        if (typeof data.text === 'undefined') {
            return null;
        }

        const terms = params.term.toUpperCase().split(' ');
        var text = data.text.toUpperCase();
        var ok = true;
        var res = terms.every(term => {
            if (text.indexOf(term) > -1 && ok == true) {
                return true;
            } else{
                ok = false;
                return false;
            }
        });

        if(res){
            return $.extend({}, data, true)
        }

        return null;

    }

    $('.selectize').select2({
        placeholder: "Selecione uma opção",
        selectOnClose: true,
        matcher: matchCustom,
        //width : '350px',
    });

    $('#flash-overlay-modal').modal();
    //$('div.alert').not('.alert-important').delay(5000).fadeOut(350);
});

$('#city').select2(optionsSelect2('cidades'));

function optionsSelect2(type)
{
    return {
        minimumInputLength: 2,
        placeholder: "Buscar",
        ajax: {
            url: '/api/'+type+'?api_token='+token,
            dataType: 'json',
            type: "GET",
            quietMillis: 50,
            data: function (params) {
                var queryParameters = {
                    q: params.term
                };
                return queryParameters;
            },
        },
    }
}

$(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
    $(this).closest(".select2-container").siblings('select:enabled').select2('open');
});

$(document).on('select2:open', () => {
    document.querySelector('.select2-search__field').focus();
});

// steal focus during close - only capture once and stop propogation
$('select.select2').on('select2:closing', function (e) {
    $(e.target).data("select2").$selection.one('focus focusin', function (e) {
        e.stopPropagation();
    });
});

function dinheiro(v)
{
    return v.toLocaleString( 'pt-Br',{style: 'currency', currency: 'BRL'});
}

$('body').on('focus', '.data', function(){
    $(this).flatpickr({
        "locale": "pt",
        'allowInput' : true,
        'dateFormat': "d/m/Y",
    });
});

$('body').on('focus', '.dataHora', function(){
    $(this).flatpickr({
        "locale": "pt",
        'allowInput' : true,
        'dateFormat': "d/m/Y H:i",
        'enableTime': true,
    });
});

$('body').on('click','.cloneTr',function(e){
    e.preventDefault();
    var table = $(this).parent().parent().parent();
    var tr = table.find('tr').first().clone();
    tr.appendTo(table).show('slow');
});

//remove Tr
$('body').on('click','.trRemove',function(e){
    e.preventDefault();
    $tr = $(this).parent().parent();
    $tr.fadeOut(400, function () {
        $tr.remove();
    });
});

$(document).ready(function () {
    $('#flash-overlay-modal').modal();
});

$('.checaCPF').on('blur', function () {
    var v = $(this).val();
    var cpf = v.replace(/\D/g, "");
    if(TestaCPF(cpf)){
        var url = '/api/aluno-cpf/'+cpf+'?api_token='+token;
        $.ajax({
            type: "GET",
            url: url,
            async: true,
            cache: false,
            success: function (data, status) {
                if (data > 0) {
                    alert('Ja temos aluno cadastrado com esse CPF, Codigo #'+data);
                }
            }
        });
    } else{
        alert('CPF invalido, necessario informar um CPF Valido para efetuar o cadastro.');
    }
});

function TestaCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;
    else if (strCPF == "11111111111") return false;
    else if (strCPF == "22222222222") return false;
    else if (strCPF == "33333333333") return false;
    else if (strCPF == "44444444444") return false;
    else if (strCPF == "55555555555") return false;
    else if (strCPF == "66666666666") return false;
    else if (strCPF == "77777777777") return false;
    else if (strCPF == "88888888888") return false;
    else if (strCPF == "99999999999") return false;

    for (i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
    return true;
}

